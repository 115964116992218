import React from "react";
import serv from "../images/serv.jpg";
import ampule from "../images/ampule.jpg";
import bullet from "../images/bullet.png";

const Services = () => {
  return (
    <main className="main-services">
      <section id="section-services">
        <img id="services-img" src={serv} alt="" />
        <img id="services-img2" src={ampule} alt="" />
        <article>
          <div id="services-head">
            <h1 className="title">Our Services</h1>
            <div className="underline"></div>
          </div>

          <h3 class="headlinered">Site selection </h3>
          <ul className="list2">
            <li>
              <img className="inline bullet" src={bullet} alt="" />
              <h4 className="inline "> Identifying trial sites </h4>
              <br />
              {/* <button onclick="readMore()" class="btnMore">...Read more</button> 
              <span class="substring">*/}
              The importance of selecting the right sites can be best understood
              in the light of statistical data that suggest that nearly
              one-third of the sites performing clinical trials have never
              enrolled a single patient. That is more than 33% of all
              participating sites, and in certain geographic areas the
              percentage is over 40%. Moreover, it is stated that, in a typical
              trial, more than one-third of sites under-enroll patients and
              roughly one-tenth of them fail to enroll even a single patient.
              Sometimes it can happen that the selected sites have access to
              patients; however, they do not have time or resources, or might
              not be motivated due to complex internal administration, or lack
              of research interest. Understanding the motivation and performance
              potential of the sites as early as possible in the process and
              finding ways to maximize their engagement will have multiple
              benefits on the success of trials. Whether you have already
              selected countries for your clinical trial, or have narrowed your
              list down, we can help you with selecting the right sites for your
              clinical trial. Our team will identify the key opinion leaders
              (KOLs) in countries of interest, as well as other potential sites
              for the study. We will conduct site outreach to gauge site
              recruitment potential, motivation and suitability for the study,
              and help you select the right sites for your study.
              {/* <button onclick="readMore()" class="btnMore">...Show less</button>
                </span> */}
            </li>
            <li>
              <img className="inline bullet" src={bullet} alt="" />
              <h4 className="inline "> Making initial contacts</h4>
            </li>
            <li>
              <img className="inline bullet" src={bullet} alt="" />
              <h4 className="inline "> Conducting evaluation visits</h4>
            </li>
            <li>
              <img className="inline bullet" src={bullet} alt="" />
              <h4 className="inline "> Establishing relationships</h4> with
              selected trial sites to ensure study targets for recruitment,
              quality and costs implementation.
            </li>
          </ul>
          <h3 class="headlinered">Stand-alone feasibility Projects </h3>
          <ul className="list2">
            <li>
              <img className="inline bullet" src={bullet} alt="" />
              <h4 className="inline "> Stand-alone feasibility</h4> is the most
              comprehensive and complexed type of feasibility analysis. When
              performed before the study starts, it enables gathering and
              analyzing critical information for setting a strong basis for
              clinical trial success. <br />
              {/* <button onclick="readMore1()" class="btnMore1">...Read more</button>
                <span class="substring1"> */}
              As a part of stand-alone feasibility, at MICRO Research we will
              conduct several key analyses, such as: competitive market
              landscape research and analysis; epidemiology of the disease
              analysis; regulatory analysis; analysis of treatment standards and
              treatment practices across different regions, countries and sites.
              We will also conduct medical and expert’s review of clinical trial
              protocol and analyze logistical aspects of your clinical study.{" "}
              <br />
              One of the keys of stand-alone feasibility is to analyze the
              investigators’ recruitment potential, evaluate their experience,
              suitability and motivation for your trial. We will also gauge
              motivation of patients to take part in the study and stay
              compliant throughout. <br />
              At MICRO Research we will perform SWOT analysis for your trial and
              point out the strengths, weaknesses, opportunities and treats for
              your trial, as well as the suggested mitigation plan. We will help
              planning your clinical trial by presenting the most successful
              strategy/ies for clinical trial conduct and completion. <br />
              The results of stand-alone feasibility, together with all types of
              analysis performed, suggested strategy/ies and the final list of
              sites will be provided to you a detailed feasibility report.
              {/* <button onclick="readMore1()" class="btnMore1">...Show less</button>
              
              <span class="text"><br />
            </span> */}
            </li>
          </ul>

          <h3 class="headlinered">Negotiation </h3>
          <ul className="list2">
            <li>
              {" "}
              <img className="inline bullet" src={bullet} alt="" />
              <h4 className="inline "> Negotiating grants and contracts </h4>
            </li>
            <li>
              {" "}
              <img className="inline bullet" src={bullet} alt="" />
              <h4 className="inline ">
                {" "}
                Assisting in budget forecasting and payments{" "}
              </h4>
              <br />
            </li>
          </ul>

          <h3 class="headlinered">
            Regulatory Authorities and Ethical Committees communication{" "}
          </h3>
          <ul className="list2">
            <li>
              {" "}
              <img className="inline bullet" src={bullet} alt="" />
              <h4 className="inline ">
                {" "}
                Performing submissions and obtaining regulatory and ethics
                approvals
              </h4>
            </li>
            <li>
              {" "}
              <img className="inline bullet" src={bullet} alt="" />
              <h4 className="inline ">
                {" "}
                Keeping the close contact with the EC and Regulatory throughout
                the clinical trial
              </h4>
            </li>
            <li>
              <img className="inline bullet" src={bullet} alt="" />
              <h4 className="inline ">
                {" "}
                SUSAR reporting to Ethic committees and Regulatory authorities
              </h4>{" "}
            </li>
          </ul>

          <h3 class="headlinered">Import and Export licenses</h3>
          <ul className="list2">
            <li>
              <img className="inline bullet" src={bullet} alt="" /> Obtaining
              import and export licensesfor Investigational Medical Product,
              Biological samples and study materials
            </li>
          </ul>

          <h3 class="headlinered">Monitoring activities</h3>
          <ul className="list2">
            <li>
              <img className="inline bullet" src={bullet} alt="" /> Monitoring
              activities (phases II-IV) including pre-study, initiation,
              interim, and closeout visits.{" "}
            </li>
            <li>
              <img className="inline bullet" src={bullet} alt="" />
              <h4 className="inline "> Visits</h4> include monitoring of proper
              informed consent procedures, compliance with protocol, GCP/ICH
              Guidelines and other applicable regulatory requirements and
              assurance of good site performance. This is accomplished by
              detailed review of subject records, essential documents,
              investigational product disposition and accountability, site
              personnel and procedures
            </li>
          </ul>

          <h3 class="headlinered">Pharmacovigilance</h3>

          <p>
            We have capacities to provide services of drug safety monitoring
            along all product lifecycle, from clinical trial to post-marketing
            period:
          </p>
          <ul className="list2">
            <li>
              <img className="inline bullet" src={bullet} alt="" />
              <h4 className="inline ">
                {" "}
                Management of cases arises from clinical trials (SUSARs and/or
                SAEs)
              </h4>
            </li>
            <li>
              <img className="inline bullet" src={bullet} alt="" />
              <h4 className="inline ">
                {" "}
                Management of cases recorded in post-marketing period
                (ICSRs/ADRs)
              </h4>
            </li>
            <li>
              <img className="inline bullet" src={bullet} alt="" />
              <h4 className="inline ">
                {" "}
                Medical review and writing of Periodic Safety Updated Reports
                (PSURs) and Risk Management Plans (RMPs)
              </h4>
            </li>
          </ul>

          <h3 class="headlinered">Translations</h3>
          <ul className="list2">
            <li>
              <img className="inline bullet" src={bullet} alt="" />
              <h4 className="inline ">
                {" "}
                We provide certified translations of the study related
                documentation
              </h4>
            </li>
          </ul>
        </article>
      </section>
    </main>
  );
};

export default Services;
